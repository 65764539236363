const capacity = {
  capacityTxt: (value) => {
    const size = Number(value)
    let result = `${value} GB`
    if (size > 1024) {
      result = `${size / 1024} TB`
    }
    return result
  },
  countTxt: (value) => {
    const result = Number(value)
    return `${result.toLocaleString('ko-KR')}건`
  }
}

export default capacity
