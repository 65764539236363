import { postLogin, getInfo, postLogout } from '@/api/login/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

export const Customer = {
  namespaced: true,

  state: () => ({
    agree1_yn: '',
    agree2_yn: '',
    order_nm: '',
    bank_code: '',
    bank_nm: '',
    acc_nm: '',
    acc_num: '',
    order_tel_no: '',
    description:''
  }),

  mutations: {
    SET_AGREE1_YN: (state, agree1_yn) => {
      state.agree1_yn = agree1_yn
    },
    SET_AGREE2_YN: (state, agree2_yn) => {
      state.agree2_yn = agree2_yn
    },
    SET_ORDER_NM: (state, order_nm) => {
      state.order_nm = order_nm
    },
    SET_BANK_CODE: (state, bank_code) => {
      state.bank_code = bank_code
    },
    SET_BANK_NM: (state, bank_nm) => {
      state.bank_nm = bank_nm
    },
    SET_ACC_NM: (state, acc_nm) => {
      state.acc_nm = acc_nm
    },
    SET_ACC_NUM: (state, acc_num) => {
      state.acc_num = acc_num
    },
    SET_ORDER_TEL_NO: (state, order_tel_no) => {
      state.order_tel_no = order_tel_no
    },
    SET_DESCRIPTION: (state, description) => {
      state.description = description
    }    
  },

  actions: {
    setAgreeYn({ commit }, agree) {
      commit('SET_AGREE1_YN', agree.agree1_yn)
      commit('SET_AGREE2_YN', agree.agree2_yn)
    },
    setAccountInfo({ commit }, accountInfo) {
      commit('SET_BANK_CODE', accountInfo.bank_code)
      commit('SET_BANK_NM', accountInfo.bank_nm)
      commit('SET_ACC_NM', accountInfo.acc_nm)
      commit('SET_ACC_NUM', accountInfo.acc_num)
      commit('SET_ORDER_TEL_NO', accountInfo.order_tel_no)
      commit('SET_ORDER_NM', accountInfo.order_nm),
      commit('SET_DESCRIPTION', accountInfo.description)
    },
    initCustomer({ commit }) {
      commit('SET_AGREE1_YN', '')
      commit('SET_AGREE2_YN', '')
      commit('SET_BANK_CODE', '')
      commit('SET_BANK_NM', '')
      commit('SET_ACC_NM', '')
      commit('SET_ACC_NUM', '')
      commit('SET_ORDER_TEL_NO', '')
      commit('SET_ORDER_NM', '')
      commit('SET_DESCRIPTION', '')
    }
  },
  getters: {
    getCustomer(state) {
      return state
    }
  }

}
