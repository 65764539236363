import { createStore } from 'vuex'
import { User } from '@/store/modules/User'
import { Customer } from '@/store/modules/Customer'

const store = createStore({
  modules: {
    User, Customer
  }
})

export default store
