<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            :default-href="pageDefaultBackLink" />
        </ion-buttons>
        <ion-title>{{ props.pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
          <ion-button @click="handleHome">
            <ion-icon
              :icon="home" />
          </ion-button>
          <ion-button @click="handleLogout">
            <ion-icon
              :icon="logOutOutline" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot></slot>
    </ion-content>
  </ion-page>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  alertController
} from '@ionic/vue'
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { logOutOutline, home } from 'ionicons/icons'

//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  pageTitle: {
    type: String,
    default: undefined
  },
  pageDefaultBackLink: {
    type: String,
    default: undefined
  }
})

const store = useStore()
const router = useRouter()

const handleLogout = async () => {
  const alert = await alertController.create({
    header: '로그아웃',
    message: '로그아웃 하시겠습니까?',
    buttons: [
      {
        text: '예',
        role: 'confirm',
        handler: async () => {
          await store.dispatch('User/fetchLogout')
          router.replace('/login')
        }
      },
      {
        text: '아니오',
        role: 'cancel'
      }
    ]
  })
  await alert.present()
}

const handleHome = () => {
  location.href = '/'
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------


</script>
