import request from '@/utils/request'
const BACK_API = '/back/api'
const ADMIN_PATH = `${BACK_API}/admin`

const postLogin = (data) => {
  return request({
    url: `${ADMIN_PATH}/login/`,
    method: 'post',
    data
  })
}

const postLogout = () => {
  return request({
    url: `${ADMIN_PATH}/logout/`,
    method: 'post'
  })
}

export { postLogin, postLogout }
