import { createApp, defineAsyncComponent } from 'vue'
import filters from './filters/index'
import App from './App.vue'
import router from './router'
import store from './store'
import { IonicVue } from '@ionic/vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/atelier-sulphurpool-light.css'

import jwtDecode from 'jwt-decode'

// import Day.js
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

const VpComponents = ['VpButton']  

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
/* swiper */
// import 'swiper/scss'
import '@ionic/vue/css/ionic-swiper.css'

/* Theme variables */
import './theme/variables.css'
import './theme/core.css'
import VpBaseLayout from './components/original/layouts/VpBaseLayout.vue'

//Custom
// import '../public/assets/css/reset.css'
// import '../public/assets/css/common.css'
//주석 풀것 

/** permission control */
import './permission'

let app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$filters = filters
app.config.globalProperties.$jwtDecode = jwtDecode

app.provide('$dayjs', dayjs)
app.provide('$filters', filters)
app.provide('$jwtDecode', jwtDecode)

app
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VueHighlightJS)
  .component('swiper', Swiper)
  .component('swiper-slide', SwiperSlide)
  .component('vp-base-layout', VpBaseLayout)

  VpComponents.forEach(component => {
    const vpComponent = defineAsyncComponent(() => import(`@/components/original/elements/${component}.vue`))
    app.component(component, vpComponent)
  })

router.isReady().then(() => {
  app.mount('#app')
})
