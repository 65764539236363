import { createRouter, createWebHistory } from '@ionic/vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/Main.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/mintit/agency/terms',
    name: 'mintitTerms',
    component: () => import('@/views/mintit/agency/Terms.vue')
  },
  {
    path: '/mintit/agency/identification',
    name: 'mintitIdentification',
    component: () => import('@/views/mintit/agency/Identification.vue')
  },
  {
    path: '/mintit/agency/account',
    name: 'mintitAccount',
    component: () => import('@/views/mintit/agency/Account.vue')
  },
  {
    path: '/mintit/agency/receipt',
    name: 'mintitReceipt',
    component: () => import('@/views/mintit/agency/Receipt.vue')
  },
  {
    path: '/mintit/agency/purchase-list',
    name: 'purchaseList',
    component: () => import('@/views/mintit/agency/PurchaseList.vue')
  },
  {
    path: '/mintit/agency/purchase-details',
    name: 'purchaseDetails',
    component: () => import('@/views/mintit/agency/PurchaseDetails.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes 
})

export default router
